import React, { useContext, useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

import "./Customers.scss";
import { FaRegUser, FaCloudDownloadAlt } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import check from "../../assets/check.svg";
import { MyContext } from "../Dashboard/Dashboard.js";
import Messages from "../Messages/Messages";
import useNetwork from "../../customHooks/useNetwork";

const Customers = () => {
  let {
    name,
    setCustomerId,
    customers,
    setBgColor,
    getCustMetaData,
    offlineEnabledUser,
  } = useContext(MyContext);
  let history = useHistory();
  //const { loading, error, data } = useQuery(Customer_query);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const networkState = useNetwork();
  const { online } = networkState;

  useEffect(() => {
    console.log("customers received ", customers);
  }, [customers]);
  useEffect(() => {
    setBgColor("#224691");
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  // const downloadMetaData = (e,id) => {
  //   getCustMetaData(id);
  //   e.stopPropagation();
  // };

  // if (loading) return <p>Loading...</p>
  const goToVesselList = (e,id, name) => {
    setCustomerId(id);
    getCustMetaData(id, name);
    history.push(`/customers/${id}/vessel/`);
  };

  return (
    <div className="customers">
      <h5>TRITON OPS</h5>
      <div className="title">
        <h4 className="welcome">
          Hi <strong>{name}</strong>
        </h4>
        <p className="question">Select a Customer</p>
      </div>
      <div className="overflow">
        {/* {error && <Messages data={error} />}
            { loading &&  <div className="loader"><Loader type="TailSpin" color=" #2546B1" height={80} width={80} timeout={3000000000000} /></div>} */}
        {!isOnline && !!!customers && (
          <span className="msg-content">
            Content is not loading since app has gone offline..
          </span>
        )}
        {customers.map((customer, i) => (
          <div
            className="cust-list"
            key={i}
            style={
              online
                ? { opacity: "1" }
                : customer.vessels
                ? { opacity: "1" }
                : { opacity: "0.5" }
            }
            onClick={(e) =>
              online || customer.vessels
                ? goToVesselList(e, customer.customerId, customer.customerName)
                : null
            }
          >
            <div className="list-box">
              <Container>
                <Row>
                  <Col xs={3}>
                    <div className="user-box">
                      <FaRegUser />
                    </div>
                  </Col>
                  <Col xs={9} className="pr-0">
                    <div className="name">
                      <h5 className="cust-name">{customer.customerDisplayName != null && customer.customerDisplayName.trim()!= '' ?customer.customerDisplayName : customer.customerName}</h5>
                      {/* <h6>ID - {customer.customerId}</h6> */}
                      <img className="check" src={check} alt="check" />
                    </div>
                  </Col>
                  {/* <Col xs={1} className="p-0">
                    {offlineEnabledUser && online &&  (
                      <FaCloudDownloadAlt id="download" className="downl" title="Click to make data available offline"
                        onClick={(e) => downloadMetaData(e,customer.customerId)}
                      />
                    )}
                  </Col> */}
                </Row>
              </Container>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customers;
