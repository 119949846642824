import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/LargeLogo_new.png";
import { BiMenuAltRight, BiHomeAlt } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { MdArrowBack } from "react-icons/md";
import { HiMenu } from "react-icons/hi";
import { gql, useLazyQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import "./Dashboard.scss";

const Sidenav = ({ ...props }) => {
  const { instance } = useMsal();
  const [closeBar, setCloseBar] = useState(true);
  const [id_token, setToken] = useState("");
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  let history = useHistory();

  useEffect(() => {}, [props.bgColor]);
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  const goBack = () => {
    history.goBack();
  };

  const userLogout = () => {
    if (isOnline) {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      history.push("/");
    }
  };

  const toCustomers = () => {
    if (localStorage.getItem("singleCustomer") == "false") {
      history.push("/customers");
    } else {
      history.push(localStorage.getItem("vesselHome"));
    }
    setCloseBar(true);
  };

  const BackButton = () => {
    if (localStorage.getItem("singleCustomer") == "true") {
      if (window.location.hash !== "#" + localStorage.getItem("vesselHome")) {
        return (
          <span
            onClick={() => goBack()}
            className="backIcon"
            style={{ color: props.bgColor }}
          >
            <MdArrowBack />
          </span>
        );
      } else {
        return null;
      }
    } else {
      if (window.location.hash !== "#/customers") {
        return (
          <span
            onClick={() => goBack()}
            className="backIcon"
            style={{ color: props.bgColor }}
          >
            <MdArrowBack />
          </span>
        );
      } else {
        return null;
      }
    }
  };
  return (
    <>
      <BackButton />
      {/* {localStorage.getItem("singleCustomer") == "false" &&
      window.location.pathname == "/customers" ? null : (
        <span onClick={() => goBack()} className="backIcon">
          <MdArrowBack />
        </span>
      )} */}
      {/* {localStorage.getItem("singleCustomer") == "true" ? (
        <span onClick={() => goBack()} className="backIcon">
          <MdArrowBack />
        </span>
      ) : window.location.pathname !== "/customers" ? (
        <span onClick={() => goBack()} className="backIcon">
          <MdArrowBack />
        </span>
      ) : null} */}

      <span
        onClick={() => setCloseBar(false)}
        className="menuIcon"
        style={{ color: props.bgColor }}
      >
        <HiMenu />
      </span>
      {!closeBar ? (
        <div className="modal-window" onClick={() => setCloseBar(true)}></div>
      ) : null}
      <ProSidebar
        collapsed={closeBar}
        collapsedWidth={"0px"}
        className="right-bar"
      >
        <SidebarHeader>
          {/* <div className="close-btn">
            <CgCloseR onClick={() => setCloseBar(true)} />
          </div> */}
          <div className="head">
            <img className="logo" src={logo} alt="logo" />
            <h1 className="title">OPS</h1>
          </div>
        </SidebarHeader>
        <hr />
        <SidebarContent>
          <Menu className="menu">
            <MenuItem
              icon={<BiHomeAlt className="icon" />}
              onClick={toCustomers}
            >
              Home
            </MenuItem>

            <MenuItem
              icon={<IoMdLogOut className="icon" />}
              onClick={userLogout}
            >
              Logout
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </>
  );
};

export default Sidenav;
