import React from 'react';
import {withStyles}   from '@material-ui/styles'

import Radio from '@material-ui/core/Radio';

const OperatorRadio = withStyles({
  root: {
    color: '#C7D0D9',
    '&$checked': {
      color: '#FF9A02',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)
export default OperatorRadio