const Constants = {
  authority:
    window.location.href.indexOf("dev") !== -1 ||
    window.location.href.indexOf("8100") !== -1
      ? "f45bbe90-9957-4ed7-810f-11c382518756"
      :
        window.location.href.indexOf("acc") !== -1
      ? ""
      : "",
  clientId:
    window.location.href.indexOf("dev") !== -1 ||
    window.location.href.indexOf("8100") !== -1
      ? "7ac6f29d-802d-4416-9b6f-a86ac77259fd"
      :  window.location.href.indexOf("acc") !== -1
      ? ""
      : "",
  redirectUrl:
    window.location.href.indexOf("8100") !== -1
      ? "http://localhost:8100/"
      : window.location.href.indexOf("dev") !== -1
      ? "https://tritonopstst-dev.azurewebsites.net/"
      : window.location.href.indexOf("acc") !== -1
      ? "https://tritonoperations-acc.azurewebsites.net/"
      : "",
};

export default Constants;


