export const DBConfig = {
  name: "TritonOpsDB",
  version: 11,
  objectStoresMeta: [
    {
      store: "unsubmitted-operations",
      storeSchema: [
        { name: "valueId", keypath: "valueId", options: { unique: true } },
        { name: "value", keypath: "value", options: { unique: false } },
      ],
    },
    {
      store: "synced-operations",
      storeSchema: [
        { name: "valueId", keypath: "valueId", options: { unique: false } },
        { name: "value", keypath: "value", options: { unique: false } },
        { name: "UTCtimestamp", keypath: "UTCtimestamp" },
      ],
    },
    {
      store: "submitted-operations",
      storeSchema: [
        { name: "valueId", keypath: "valueId", options: { unique: false } },
        { name: "value", keypath: "value", options: { unique: false } },
        { name: "UTCtimestamp", keypath: "UTCtimestamp" },
      ],
    },
    {
      store: "time-tracked",
      // storeConfig: { keypath: 'id', autoIncrement: true },
      storeSchema: [
        { name: "valueId", keypath: "valueId", options: { unique: true } },
        { name: "vesselId", keypath: "vesselId", options: { unique: false } },
        {
          name: "customerId",
          keypath: "customerId",
          options: { unique: false },
        },
        {
          name: "sensorInput",
          keypath: "sensorInput",
          options: { unique: false },
        },
      ],
    },
    {
      store: "user-info",
      storeSchema: [
        { name: "email", keypath: "emailId", options: { unique: true } },
        { name: "uid", keypath: "uidId", options: { unique: true } },
        { name: "pin", keypath: "pinId", options: { unique: false } },
        { name: "timeStamp", keypath: "timeStamp", options: { unique: false } },
        { name: "response", keypath: "response", options: { unique: false } },
        { name: "isLoggedIn", keypath: "isLoggedIn", options: { unique: false } },
      ],
    },
    {
      store: "version-info",
      storeSchema: [
        {name: "email", keypath: "emailId", options: { unique: true } },
        {name: "custId", keypath: "custId", options: { unique: true } },
        {name: "version"},
        {name: "vessels"}
      ]
    },
    {
      store: "image-store",
      storeSchema: [
        { name: "VRid", keypath: "vrid", options: { unique: true } },
      ]
    },
    {
      store: 'files',
      storeSchema: [
        { name: 'time', keypath: 'name', options: { unique: false } },
        { name: 'type', keypath: 'type', options: { unique: false } },
        { name: 'content', keypath: 'content', options: { unique: false } },
        { name: 'cid', keypath: 'cid', options: { unique: false } },
        { name: 'vid', keypath: 'vid', options: { unique: false } },
        { name: 'rid', keypath: 'rid', options: { unique: false } }
      ]
    },
    {
      store: 'comments',
      storeSchema: [
        {name: 'id', keypath: 'id', options:{unique: false}},
        {name: 'data', keypath: 'data', options:{unique: false}},
      ]
    },
    {
      store: "images",
      storeSchema: [
        {name: 'id', keypath: 'id', options:{unique: false}},
        {name: 'data', keypath: 'data', options:{unique: false}},
        {name: 'time', keypath: 'time', options:{unique: false}},
      ]
    }
  ],
};
