import React, { FC, useEffect } from "react";
import { Toast, Button, Container, Row, Col, Modal } from "react-bootstrap";
import * as serviceWorker from "./serviceWorkerRegistration";
import "./index.css";
const ServiceWorkerWrapper: FC = (props) => {
  const [showReload, setShowReload] = React.useState(false);
  const [showBlocker, setShowBlocker] = React.useState(true);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowBlocker(false);
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onRegSuccess = () => {
    setShowBlocker(false);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate, proceed: onRegSuccess });
    if(window.location.hostname === "localhost"){
      setShowBlocker(false);
    }
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <>
        <div className="toast-wrap">
          <Modal
            backdrop="static"
            show={showReload}
            centered
            style={{ color: "#2546b1" }}
          >
            <Modal.Header>
              <Modal.Title>Update available!</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ color: "#2546b1" }}>
              <Row>
                <Col xs={8}>
                  <div className="text-left">Please update to proceed.</div>
                </Col>
                <Col xs={4}>
                  <Button onClick={reloadPage}>Update</Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          {props.children}
        </div>
     
    </>
  );
};

export default ServiceWorkerWrapper;
