import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard.js";
import Login from "../components/Login/login";
import Customers from "../components/Customers/Customers.js";
import Vessel from "../components/Vessels/vessel.js";
import ProtectedRoute from "../ProtectedRoute";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import Operations from "../components/Operations/Operations.js";
import Events from "../components/Events/Events";
import Rooms from "../components/Rooms/Rooms.js";
import VirtualSensor from "../components/Sensors/VirtualSensor.js";
import ServiceWorkerWrapper from "../serviceWorkerWrapper";

import { client } from "../services/GraphqlService.js";
import { DBConfig } from "../DBConfig";
import { initDB } from "react-indexed-db";
import { ApolloProvider } from "@apollo/client";
import { render } from "@testing-library/react";

import Downtime from "../components/Downtime/Downtime";
import ServiceRequest from "../components/ServiceRequest/ServiceRequest.js";
import ServiceRequestReadOnly from "../components/ServiceRequestReadOnly/ServiceRequestReadOnly.js";
import ServiceDetails from "../components/ServiceDetails/ServiceDetails.js";
import NewServiceRequest from "../components/NewServiceRequest/NewServiceRequest.js";
import ServiceRequestCaptain from "../components/ServiceDetailsCaptain/ServiceDetailsCaptain.js";
import ServiceRequestSuper from "../components/ServiceRequestSuper/ServiceRequestSuper.js";
import ServiceDetailsCaptain from "../components/ServiceDetailsCaptain/ServiceDetailsCaptain.js";
import ServiceDetailsSuper from "../components/ServiceDetailsSuper/ServiceDetailsSuper.js";
import ServiceDetailsResolvedCaptain from "../components/ServiceDetailsResolvedCaptain/ServiceDetailsResolvedCaptain.js";
import ServiceDetailsAccessPending from "../components/ServiceDetailsAccessPending/ServiceDetailsAccessPending.js";
// import useConfig from "../customHooks/useConfig.js";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../helpers/authConfig";
import { MsalProvider } from "@azure/msal-react";
initDB(DBConfig);

export const msalInstance = new PublicClientApplication(msalConfig);

const App = (props) => {
  msalInstance.handleRedirectPromise().then((resp) => {
    if (resp && resp.account) msalInstance.setActiveAccount(resp.account);
  });
  // const [config] = useConfig();
  // if (!config?.auth) return <><ServiceWorkerWrapper>
  //   <div className="blocker">
  //     <p className="text-center">Fetching config...</p>
  //   </div>
  // </ServiceWorkerWrapper></>;

  // // MSAL configuration
  // const configuration = {
  //   auth: config.auth,
  //   cache: config.cache,
  // };

  
  return (
    <>
      <ServiceWorkerWrapper>
        {/* {
          !config?.auth ? <div className="blocker">
            <p className="text-center">Fetching config...</p>
          </div> : */}

            <>

              <Downtime />
              <MsalProvider instance={msalInstance}>
                {/* <AuthProvider> */}
                <HashRouter basename="/">
                  <Switch>
                    <Route path="/" exact component={Login} />
                    <ProtectedRoute
                      component={({ }) => (
                        <ApolloProvider client={client}>
                          <Dashboard>
                            <Route path="/customers" exact component={Customers} />
                            <Route
                              path="/customers/:cid/vessel"
                              exact
                              render={(props) => <Vessel {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations"
                              exact
                              render={(props) => <Operations {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/events"
                              exact
                              render={(props) => <Events {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceRequest"
                              exact
                              render={(props) => <ServiceRequest {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceRequestReadOnly"
                              exact
                              render={(props) => <ServiceRequestReadOnly {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceRequestCaptain"
                              exact
                              render={(props) => <ServiceRequestCaptain {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceRequestSuper"
                              exact
                              render={(props) => <ServiceRequestSuper {...props} />}
                            />
                            <Route
                              //path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails"
                              path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails/:serviceId"
                              exact
                              render={(props) => <ServiceDetails {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceDetailsCaptain"
                              // path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails/:sid"
                              exact
                              render={(props) => <ServiceDetailsCaptain {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceDetailsSuper"
                              // path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails/:sid"
                              exact
                              render={(props) => <ServiceDetailsSuper {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceDetailsResolvedCaptain"
                              // path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails/:sid"
                              exact
                              render={(props) => <ServiceDetailsResolvedCaptain {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/serviceDetailsAccessPending"
                              // path="/customers/:cid/vessel/:vid/operations/serviceRequest/serviceDetails/:sid"
                              exact
                              render={(props) => <ServiceDetailsAccessPending {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/newServiceRequest"
                              exact
                              render={(props) => <NewServiceRequest {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/rooms"
                              exact
                              render={(props) => <Rooms {...props} />}
                            />
                            <Route
                              path="/customers/:cid/vessel/:vid/operations/rooms/:rid/sensors"
                              render={(props) => <VirtualSensor {...props} />}
                            />
                          </Dashboard>
                        </ApolloProvider>
                      )}
                    />
                  </Switch>
                </HashRouter>-
                {/* </AuthProvider> */}
              </MsalProvider>
            </>
        {/* } */}
      </ServiceWorkerWrapper>


    </>
  );
};

export default App;
