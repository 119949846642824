import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './ServiceDetailsSuper.scss'

function ParameterForm(props) {
    const [name, setName] = useState('');
    const [pvalue, setValue] = useState('');
    const [comment, setComment] = useState('');

    const changeName = (event) => {
        setName(event.target.value);
    };

    const changeValue = (event) => {
        setValue(event.target.value);
    };

    const changeComment = (event) => {
        setComment(event.target.value);
    };

    const transferValue = (event) => {
        event.preventDefault();
        const val = {
            name,
            pvalue,
            comment
        };
        props.func(val);
        clearState();
    };

    const clearState = () => {
        setName('');
        setValue('');
        setComment('');
    };

    return (
        <div>
            {/* <label>Name</label>
	<input type="text" value={name} onChange={changeName} />
    <br></br>
	<label>City</label>
	<input type="text" value={city} onChange={changeCity} />
    <br></br>
	<button onClick={transferValue}> Click Me</button> */}
            <Form className='parameter-input-form'>
                <div className='form-header'>Parameter Readings</div>
                <div className="name-input-wrapper">
                <label className='parameter-name'>Name</label>
                <input type="text" className='parameter-name-input' value={name} onChange={changeName} />
                </div>
                <br></br>
                <div className="value-input-wrapper">
                <label className='parameter-value'>Value</label>
                <input type="text" className='parameter-value-input' value={pvalue} onChange={changeValue} />
                </div>
                <br></br>
                <div className="comment-input-wrapper">
                <label className='comment'>Comment</label>
                <input type="text" className='comment-value' value={comment} onChange={changeComment} />
                </div>
                <br></br>
                <Form.Group className='button-group'>
                    <Button className='add-button' onClick={transferValue}>Add</Button>
                    <Button className='clear-button' variant='secondary' onClick={clearState}>Clear</Button>
                </Form.Group>
            </Form>
        </div>



    );
}

export default ParameterForm;
