import React from 'react';
import {
  Fade  
} from '@material-ui/core';
import {withStyles}   from '@material-ui/styles'
import InputBase from '@material-ui/core/InputBase';

const OperatorInputText = withStyles((theme) => ({
  root: {
    display: 'flex',
    'label + &': {
      marginTop: '1rem',
      fontSize: '14pt'
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
    
    },
  },
}))(InputBase);
export default OperatorInputText