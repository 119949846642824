const url = {
    graphQlendpoint: localStorage.getItem('QLep'),
    apiEndPoint: localStorage.getItem('ApiEndPoint'),
    fnKey: localStorage.getItem('fnKey')
};

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...url
};