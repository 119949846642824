import axiosInstance from '../../services/axiosInstance';
import config from "../../config";

const postComments = async (cid, vid, rid, data, code) => {
  try {
    const response = await axiosInstance.post(`api/customer/${cid}/vessel/${vid}/room/${rid}/comments?code=${code}`, data);
    return response.data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};

const getComments = async (cid, vid, rid, start, end, code) =>{
    try {
        const response = await axiosInstance.get(`api/customer/${cid}/vessel/${vid}/room/${rid}/comments?startTime=${start}&endTime=${end}&code=${code}`);
        return response.data;
      } catch (error) {
        console.error('API call failed:', error);
        throw error;
      }
}

const getImage = async (cid, vid, rid, time, code) =>{
    try {
        const response = await axiosInstance.get(`api/customer/${cid}/vessel/${vid}/room/${rid}/comments/file?filetype=image&time=${time}&code=${code}`);
        return response.data;
      } catch (error) {
        console.error('API call failed:', error);
        throw error;
      }
}
 
export { postComments, getComments, getImage };