import React, {useState } from "react";
import Select from "react-select";

const SelectBox = ({ options }) => {
  const [optionSelected, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <Select
      options={options}
      isLoading={!options}
      closeMenuOnSelect={true}
      onChange={handleChange}
      value={optionSelected}
      name={"your_select_name"}
      placeholder="Select Service Provider"
    />
  );
};

export default SelectBox;