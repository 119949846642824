
import React, { useContext, useState } from 'react';
import { MyContext } from '../Dashboard/Dashboard.js';
import { Container, Col, Button, Row, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './ServiceDetailsCaptain.scss'
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const ServiceDetailsCaptain = (props) => {
    let { name } = useContext(MyContext);
    let history = useHistory();
    const { match: { params } } = props;
    const { cid, vid } = params;
    let vesselName = localStorage.getItem("vesselName");
    let vesselImage = localStorage.getItem("vesselImage");
    //Approve-Deny Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Input comments Modal
    const [showInput, setShowInput] = useState(false);
    const handleCloseInput = () => setShowInput(false);
    const handleShowInput = () => setShowInput(true);

    const openCommentDialog = () => {
        setShow(false); setShowInput(true);
    }

    return (
        <div className="customers service-details-wrapper">
            <h5>TRITON OPS</h5>
            <div className="title">
                <div className="request-header">Request Details</div>
                <div className="welcome">
                    <div className="user-box">
                        <img src={vesselImage} alt="" />
                    </div>
                    <div className="vessel-name">{vesselName}</div>
                    <div className="vessel-number">IMO : {vid}</div>
                </div>
            </div>
            <div className="issue-description-wrapper">
                <Row className="issue-container">
                    <div className="issue-description">Abnormal Sound from Engine</div>
                    <div className="part-specification">Main Engine</div>
                    <div className='issue-time-status'>
                        <div className="issue-timestamp">5th July 2021</div>
                        <div className="issue-status-description">Pending with Captain</div>
                    </div>
                    <div className="detailed-description">
                        <div className='description-header'>Description</div>
                        <div className='description-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        <div className='description-images'>
                            <img src="https://picsum.photos/200/300?random=1" alt="image1" width="100" height="50"></img>
                            <img src="https://picsum.photos/200/300?random=2" alt="image2" width="100" height="50"></img>
                        </div>
                    </div>
                </Row>
            </div>
            <div className="workflow-wrapper">
                <div className='workflow-header'>Workflow</div>
                <div className='workflow-steps'>
                    <div className="step-summary">
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Issue Reported</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">John Smith ,Chief Engineer</div>
                            <div className="step-status">Completed</div>
                        </div>
                    </div>
                    <div className="step-summary" onClick={handleShow}>
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Captain's Approval</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">James ,Captain</div>
                            <div className="step-status pending">Pending</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pending-steps-wrapper'>
                <div className='task-header'>To be started</div>
                <div className='task-list-body'>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Data Check</div>
                    </div>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Estimate Time and Data</div>
                    </div>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Remote Access Approval</div>
                    </div>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Report Work</div>
                    </div>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Review Work</div>
                    </div>
                </div>

            </div>
            <div className='approve-modal-wrapper' onClick={handleClose} >
                <Modal
                    className="approve-modal"
                    animation={false}
                    show={show}
                    onHide={handleClose}
                    backdrop={false}
                    style={{ background: 'transparent', height: '26' }}
                    onClick={e => e.stopPropagation()}
                >
                    <Modal.Body>
                        <div className='approve-deny-button-group'>
                            <Button variant="secondary deny-button" onClick={() => openCommentDialog()}>
                                DENY
                            </Button>
                            <Button variant="secondary approve-button" onClick={() => openCommentDialog()}>APPROVE</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className='comment-modal-wrapper'>
                <Modal
                    className="comment-modal"
                    show={showInput}
                    onHide={handleCloseInput}
                    animation={false}
                    backdrop={false}
                    style={{ background: 'transparent', height: '26' }}>
                    <Modal.Body>
                        <Form.Group className="mb-0" controlId="ControlTextarea">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control as="textarea" rows={5} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='comment-button-group'>
                            <Button variant="secondary close-form" onClick={handleCloseInput}>
                                CANCEL
                            </Button>
                            <Button variant="secondary submit-approval" onClick={handleCloseInput}>
                                SUBMIT
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>

    );
}

export default ServiceDetailsCaptain;