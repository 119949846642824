import { useIsAuthenticated } from "@azure/msal-react";
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import {
    InteractionStatus
} from "@azure/msal-browser";
import {
    useMsal,
} from "@azure/msal-react";

const ProtectedRoute = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const [authState, setAuthState] = useState(false);
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {
        setAuthState(isAuthenticated)
    }, [isAuthenticated]);

    useEffect(() => {
    }, [instance, accounts, inProgress]);
    const Component = props.component;

    return (
        <div>
            {
                authState || localStorage.getItem('offline_user') ? <Component /> :
                    <div className="unauthorized">
                        {
                            inProgress == "logout" ? <p>Logging out...</p> : <>
                                <p>You are not authenticated. Please proceed Login</p>
                                <Link to='/' className="link">Go To Login</Link></>
                        }

                    </div>
            }
        </div>
    )
};

export default ProtectedRoute;