import React, { useState, useEffect, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import { FaExclamationTriangle } from "react-icons/fa";
const Downtime = (props) => {
  let [config, setConfig] = useState(false);
  let [show, setShow] = useState(false);
  let [message, setMessage] = useState("");
  let [variant, setVariant] = useState("");
  var CryptoJS = require("crypto-js");


  useEffect(() => {
    if(localStorage.getItem("bannerURL")) {getURL();} 
  }, []);

  const getURL = () => {
 
    let bannerURL = localStorage.getItem("bannerURL");

    fetch(bannerURL, { cache: "no-cache" })
      .then((response) => response.blob())
      .then((blobData) => {
        // Handle the blob data here
        return blobData.text();
      })
      .then((jsonText) => {
        let jsonData=JSON.parse(jsonText);
        if(jsonData && jsonData.enableDownTime){
          if(jsonData.messageType=='info'){
            setVariant('info')
          }
          else{
            setVariant('warning')
          }
        setShow(true);
          let downError;
          downError = jsonData.message
            ? jsonData.message
            : "We are experiencing issues with Triton , Please get in contact with an administrator for further details";
          setMessage(downError);
        } else {
          setShow(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching blob data:", error);
      });
  };
  return (
    <>
      {show ? (
        <Alert key={variant} variant={variant} className="banner-message" onClose={() => setShow(false)} dismissible>
        <div className="icon">
          <span className="float-left info-icon">
            <FaExclamationTriangle />
          </span>
        </div>
        <div className="downtime">{message}</div>
      </Alert>
      ) : null}
    </>
  );
};

export default Downtime;
