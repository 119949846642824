import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../Dashboard/Dashboard.js";
import { Container, Col, Button, Row } from "react-bootstrap";
import "./ServiceDetailsResolvedCaptain.scss";
import { useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const ServiceDetailsResolvedCaptain = (props) => {
  let { name } = useContext(MyContext);
  let history = useHistory();
  const {
    match: { params },
  } = props;
  //vessel id
  const { cid, vid } = params;
  let vesselName = localStorage.getItem("vesselName");
  let vesselImage = localStorage.getItem("vesselImage");

  return (
    <div className="customers service-details-wrapper">
      <h5>TRITON OPS</h5>
      <div className="title">
        <div className="request-header">Request Details</div>
        <div className="welcome">
          <div className="user-box">
            <img src={vesselImage} alt="" />
          </div>
          <div className="vessel-name">{vesselName}</div>
          <div className="vessel-number">IMO : {vid}</div>
        </div>
      </div>
      <div className="issue-description-wrapper">
        <Row className="issue-container">
          <div className="issue-description">Abnormal Sound from Engine</div>
          <div className="part-specification">Main Engine</div>
          <div className="issue-time-status">
            <div className="issue-timestamp">5th July 2021</div>
            <div className="issue-status-description">Completed</div>
          </div>
          <div className="detailed-description">
            <div className="description-header">Description</div>
            <div className="description-details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div className="description-images-complete">
              <img
                src="https://picsum.photos/200/300?random=1"
                alt="image1"
                width="100"
                height="50"
              ></img>
              <img
                src="https://picsum.photos/200/300?random=2"
                alt="image2"
                width="100"
                height="50"
              ></img>
            </div>
          </div>
        </Row>
      </div>
      <div class="work-tab-selection">
        <Tabs
          defaultActiveKey="workflow"
          id="workflow-tabs-captain"
          className="worflow-tab-wrapper mb-1"
        >
          <Tab eventKey="workflow" title="Work Flow">
            <div className="workflow-wrapper">
              <div className="workflow-header">Workflow</div>
              <div className="workflow-steps">
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">Issue Reported</div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">
                      John Smith , Chief Engineer
                    </div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">Captain's Approval</div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">James , Captain</div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">Data Check</div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">
                      Mark ,Superintendant
                    </div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">
                      Estimate Time and Date
                    </div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">
                      Julia , Service Provider
                    </div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">
                      Remote Access Approval
                    </div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">James , Captain</div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
                <div className="step-summary">
                  <div className="worflow-diagram">
                    <div className="workflow-round"></div>
                    <div className="workflow-arrow"></div>
                  </div>
                  <div className="step-details">
                    <div className="step-description">Report Work</div>
                    <div className="step-timestamp">5th May 2021,5:20 PM</div>
                  </div>
                  <div className="step-approval">
                    <div className="step-approver-details">
                      Julia , Service Provider
                    </div>
                    <div className="step-status">Completed</div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="workreport" title="Work Report">
            <div className="work-report-wrapper">
              <div className="step-details">
                <div class="title-header">Title:</div>
                <div className="step-title">Loud Knocking Sounds</div>
                <div class="description-header">Description:</div>
                <div className="step-description">
                  Greasing had to be done to avoid abrasion
                </div>
                <div className="description-images-pending">
                  <img
                    src="https://picsum.photos/200/300?random=1"
                    alt="image1"
                    width="100"
                    height="50"
                  ></img>
                  <img
                    src="https://picsum.photos/200/300?random=2"
                    alt="image2"
                    width="100"
                    height="50"
                  ></img>
                </div>
              </div>
              <div className="step-approval">
                <div className="step-status">Completed by:</div>
                <div className="step-approver-details">
                  Julia , Service Provider
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="comments" title="Comments">
            <div className="comment-wrapper">
              <div className="comment-section">
                <div className="comment-description">
                  "Issue request looks legitimate.Approved from my end"
                </div>
                <div className="comment-owner">
                  Commented by : James , Captain{" "}
                </div>
                <div className="comment-timestamp">
                  Date : 5th May 2021
                </div>
              </div>
              <div className="comment-section">
                <div className="comment-description">
                  "Parameters verified.Assigning the request to Service
                  provider"
                </div>
                <div className="comment-owner">
                  Commented by : Mark , Superintendant{" "}
                </div>
                <div className="comment-timestamp">
                  Date : 5th May 2021
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ServiceDetailsResolvedCaptain;
