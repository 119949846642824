import axios from "axios";
import config from "../config";
import { msalInstance } from "../containers/App";
import jwt_decode from "jwt-decode";
const axiosInstance = axios.create({
  // baseURL: `${atob(config.apiEndPoint)}` ,
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    let token = await getToken(config);
    let access_decode = jwt_decode(token.accessToken);
    if (token) {
      config.headers = {
        Authorization: "Bearer " + token.accessToken,
        "Authorization-Id": token.idToken,
        "User-Email": btoa(
          access_decode.email ? access_decode.email : access_decode.unique_name
        ),
        Application: btoa(token.application),
        "Session-Id": token.sessionId,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getToken = async (config) => {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["User.Read"],
    account: account,
  };

  const token = await msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      let idToken = accessTokenResponse.idToken;
      let sessionId = accessTokenResponse.account.idTokenClaims.sid;
      let application = accessTokenResponse.account.idTokenClaims.aud;
      let tokens = {
        accessToken,
        idToken,
        sessionId,
        application,
      };
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("application", application);
      config.baseURL = atob(localStorage.getItem("ApiEndPoint"));
      return tokens;
      // Call your API with token
    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      if (error) {
        msalInstance
          .acquireTokenPopup(accessTokenRequest)
          .then(function (accessTokenResponse) {
            // Acquire token interactive success
            let accessToken = accessTokenResponse.accessToken;
            let idToken = accessTokenResponse.idToken;
            let sessionId = accessTokenResponse.account.idTokenClaims.sid;
            let application = accessTokenResponse.account.idTokenClaims.aud;
            let tokens = {
              accessToken,
              idToken,
              sessionId,
              application,
            };
            config.baseURL = atob(localStorage.getItem("ApiEndPoint"));
            return tokens;
            // Call your API with token
          })
          .catch(function (error) {
            // Acquire token interactive failure
            console.log("reauth failed", error);
            msalInstance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          });
      }
      console.log(error);
    });
  config.baseURL = atob(localStorage.getItem("ApiEndPoint"));
  return token;
};

export default axiosInstance;
