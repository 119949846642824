
import React, { useContext, useState } from 'react';
import { MyContext } from '../Dashboard/Dashboard.js';
import { Container, Col, Button, Row, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './ServiceDetailsAccessPending.scss'
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const ServiceDetailsAccessPending = (props) => {
    let { name } = useContext(MyContext);
    let history = useHistory();
    const { match: { params } } = props;
    const { cid, vid } = params;
    let vesselName = localStorage.getItem("vesselName");
    let vesselImage = localStorage.getItem("vesselImage");

    //Approve-Deny Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="customers service-details-wrapper">
            <h5>TRITON OPS</h5>
            <div className="title">
                <div className="request-header">Request Details</div>
                <div className="welcome">
                    <div className="user-box">
                        <img src={vesselImage} alt="" />
                    </div>
                    <div className="vessel-name">{vesselName}</div>
                    <div className="vessel-number">IMO : {vid}</div>
                </div>
            </div>
            <div className="issue-description-wrapper">
                <Row className="issue-container">
                    <div className="issue-description">Abnormal Sound from Engine</div>
                    <div className="part-specification">Main Engine</div>
                    <div className='issue-time-status'>
                        <div className="issue-timestamp">5th July 2021</div>
                        <div className="issue-status-description">Pending</div>
                    </div>
                    <div className="detailed-description">
                        <div className='description-header'>Description</div>
                        <div className='description-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        <div className='description-images-pending'>
                            <img src="https://picsum.photos/200/300?random=1" alt="image1" width="100" height="50"></img>
                            <img src="https://picsum.photos/200/300?random=2" alt="image2" width="100" height="50"></img>
                        </div>
                    </div>
                </Row>
            </div>
            <div className="workflow-wrapper">
                <div className='workflow-header'>Workflow</div>
                <div className='workflow-steps'>
                    <div className="step-summary">
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Issue Reported</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">John Smith ,Chief Engineer</div>
                            <div className="step-status">Completed</div>
                        </div>
                    </div>
                    <div className="step-summary">
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Captain's Approval</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">James ,Captain</div>
                            <div className="step-status">Completed</div>
                        </div>
                    </div>
                    <div className="step-summary">
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Data Check</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">Mark ,Superintendant</div>
                            <div className="step-status">Completed</div>
                        </div>
                    </div>
                    <div className="step-summary">
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Estimate Data and Time</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            <div className="step-approver-details">Julie ,Service Provider</div>
                            <div className="step-status">Completed</div>
                        </div>
                    </div>
                    <div className="step-summary" onClick={handleShow}>
                        <div className="worflow-diagram">
                            <div className="workflow-round"></div>
                            <div className="workflow-arrow"></div>
                        </div>
                        <div className="step-details">
                            <div className="step-description">Remote Access Approval</div>
                            <div className="step-timestamp">5th May 2021,5:20 PM</div>
                        </div>
                        <div className="step-approval">
                            {/* <div className="step-approver-details">James ,Captain</div>
                            <div className="step-status pending">Awaiting Approval</div> */}
                            <div className="step-approver-details">Mark ,Superintendant</div>
                            <div className="step-status pending">Awaiting Approval</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pending-steps-wrapper'>
                <div className='task-header'>To be started</div>
                <div className='task-list-body'>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Report Work</div>
                    </div>
                    <div className='task-list-details'>
                        <div className="workflow-round"></div>
                        <div className='task-name'>Review Work</div>
                    </div>
                </div>

            </div>
            <div className='access-approve-modal-wrapper'>
                <Modal
                    className="access-approve-modal"
                    animation={false}
                    show={show}
                    onHide={handleClose}
                    backdrop={false}
                    style={{ background: 'white' }}
                >
                    <Modal.Header closeButton>
                        <div className='access-request-header'>Access Request</div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="service-provider-details-wrapper">
                            <div className="service-provider-image">
                                <img src="https://picsum.photos/200/300?random=1"></img>
                            </div>
                            <div className="service-provider-name">Certified Supplier</div>
                            <div className="service-provider-request-description">
                                PON-CAT Netherlands has requested access
                            </div>
                            <div className="vessel-details">
                                <div className="welcome">
                                    <div className="user-box">
                                        <img src={vesselImage} alt="" />
                                    </div>
                                    <div className="vessel-name">{vesselName}</div>
                                    <div className="vessel-number">IMO : {vid}</div>
                                </div>
                            </div>
                            <div className='service-request-reason'>
                                <div className='reason-header'>Reason:</div>
                                <div className='reason-description'>Diesel Generator Firm Upgrade</div>
                            </div>
                            <div className='service-requestedBy-details'>
                                <div className='service-requestBy-label'>Requested by</div>
                                <div className='service-requestor-name'>Julie Adams</div>
                            </div>
                            <div className='service-request-timestamp'>
                                <div className='service-requesttimestamp-label'>Date & Time</div>
                                <div className='service-request-timestamp'>5th July 2022,5:30 PM</div>
                            </div>
                            <div className='approval-denial-comments'>
                                <Form.Group className="mb-0" controlId="ControlTextarea">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control as="textarea" rows={5} />
                                </Form.Group>
                            </div>
                            <div className='approve-deny-button-group'>
                                <Button variant="secondary deny-button" onClick={handleClose}>
                                    DENY
                                </Button>
                                <Button variant="secondary approve-button">APPROVE</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    );
}

export default ServiceDetailsAccessPending;