import React from 'react';

const Messages = (props) => {
    const { data } = props   
    return (
        <div style={msgStyle}> <p>{data.graphQLErrors.map(({ message }, i) => (
             <span key={i}>{message}</span>
             ))}</p>
              { data.networkError && data.networkError.result && <p>{data.networkError.result.errors.map(({message }, i) => (
             <span key={i}>{message.replace("Context creation failed:", "")}</span>
            ))}</p>}
        </div>
    );
};

const msgStyle ={
    padding: '2rem',
    color: 'rgba(255, 99, 2, 0.82)'
}

export default Messages;